import {useTheme} from 'next-themes';
import {Toaster as Sonner} from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({...props}: ToasterProps) => {
  const {theme = 'system'} = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps['theme']}
      className="toaster group"
      toastOptions={{
        classNames: {
          success:
            'group toast group-[.toaster]:bg-background group-[.toaster]:text-primary group-[.toaster]:border-primary group-[.toaster]:shadow-lg',
          error:
            'group toast group-[.toaster]:bg-background group-[.toaster]:text-red-500 group-[.toaster]:border-red-500 group-[.toaster]:shadow-lg',
          info: 'group toast group-[.toaster]:bg-background group-[.toaster]:text-tertiary group-[.toaster]:border-tertiary group-[.toaster]:shadow-lg',
          warning:
            'group toast group-[.toaster]:bg-background group-[.toaster]:text-secondary group-[.toaster]:border-secondary group-[.toaster]:shadow-lg',
          default:
            'group toast group-[.toaster]:bg-background group-[.toaster]:text-primary group-[.toaster]:border-primary group-[.toaster]:shadow-lg',
          description: 'group-[.toast]:text-muted-foreground',
          actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary/20',
          cancelButton: 'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
        },
      }}
      {...props}
    />
  );
};

export {Toaster};
