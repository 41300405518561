import type {LinksFunction, LoaderFunctionArgs, MetaFunction} from '@remix-run/node';
import {
  ClientLoaderFunctionArgs,
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from '@remix-run/react';
import {captureRemixErrorBoundaryError, withSentry} from '@sentry/remix';
import {toast} from 'sonner';
import {useClearFormOnPathChange} from './components/hooks/useAutoSaveForm';
import {Toaster} from './components/ui/sonner';
import {sessionService} from './routes/api.$trpc/session.server';
import style from './tailwind.css?url';

export const links: LinksFunction = () => {
  return [{rel: 'stylesheet', href: style}];
};

export const meta: MetaFunction = () => {
  return [
    {title: 'Vaerdict'},
    {name: 'description', content: 'Vaerdict'},
    {name: 'viewport', content: 'width=device-width,initial-scale=1'},
    {charset: 'utf-8'},
  ];
};

export const loader = async (args: LoaderFunctionArgs) => {
  const {headers, flash} = await sessionService.toast.getFlash(args.request.headers);
  return json({data: flash}, {headers});
};

export async function clientLoader({serverLoader, request}: ClientLoaderFunctionArgs) {
  const serverData = await serverLoader<typeof loader>();

  if (!serverData.data) {
    return null;
  }

  if (serverData.data.type === 'success') {
    toast.success(serverData.data.message);
    return null;
  }
  if (serverData.data.type === 'error') {
    toast.error(serverData.data.message);
    return null;
  }
  if (serverData.data.type === 'warning') {
    toast.warning(serverData.data.message);
    return null;
  }
  return null;
}

const App = () => {
  useClearFormOnPathChange();
  return (
    <html lang="fr">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <script defer data-domain="app.vaerdict.fr" src="https://analytics.vaerdict.fr/js/script.js"></script>
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <Toaster position="top-right" offset={64} />
      </body>
    </html>
  );
};

export default withSentry(App);

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};
